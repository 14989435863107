import React,{useEffect}  from 'react'
import Header from '../Common/Mobile/Header'
import DetailsSmControle from '../Common/Mobile/DetailsSmControle'
import WhoWeAre from '../Common/Mobile/WhoWeAre'
import TalkToUs from '../Common/Mobile/TalkToUs'
import Footer from '../Common/Footer'
import FeaturedCustomers from '../Common/FeaturedCustomers'
import CarroselServices from '../Common/CarroselServices'


const InitialPageMobile = ({nome,handleNomeChange,email,handleEmailChange,telefone,handleTelefoneChange,motivo,handleMotivoChange,message,handleMessageChange,onClickButtonSubmit,scrollToHash}) => {
  useEffect(() => {
    scrollToHash();
    window.addEventListener('hashchange', scrollToHash);
    return () => {
      window.removeEventListener('hashchange', scrollToHash);
    };

  },);

  return (
    <>
    <Header />
    <div id='analise'></div>
    <DetailsSmControle/>
    <div id='smcontrole'></div>
    <WhoWeAre/>
    <CarroselServices/>
    <div id='contato'></div>
    <TalkToUs nome={nome} handleNomeChange={handleNomeChange} email={email} handleEmailChange={handleEmailChange} telefone={telefone} handleTelefoneChange={handleTelefoneChange} motivo={motivo} handleMotivoChange={handleMotivoChange} message={message} handleMessageChange={handleMessageChange} onClickButtonSubmit={onClickButtonSubmit}/>
    <FeaturedCustomers/>
    <Footer/>
    </>
  )
}

export default InitialPageMobile
