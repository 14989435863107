import React,{useEffect} from 'react'
import LogoSm from "../../../assets/images/LogoSMOficial.svg"
import { TEXTOS } from '../../../constants/constants'
import { useNavigate } from 'react-router-dom'
import {setPage}  from "../../../store/pageSlice"
import { useDispatch } from 'react-redux'


const Header = () => {  
  function scrollToHash() {
    if (window.location.hash) {
      const targetElement = document.querySelector(window.location.hash);
  
      if (targetElement) {
        const offset = targetElement.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }
    }
  }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const redirectHome = () =>{
    navigate("/");
  }
  const onClickServices = async () =>{
    dispatch(setPage("second"));
  }
  const onClickAnalise = async () =>{
    dispatch(setPage("first"));
    navigate(TEXTOS.pathAnalise);
    scrollToHash();
  }
  const onClickQuemSomos = async () =>{
    dispatch(setPage("first"));
    navigate(TEXTOS.pathQuemSomos);
    scrollToHash();
  }
  const onClickContato = async () =>{
    dispatch(setPage("first"));
    navigate(TEXTOS.pathContato);
    scrollToHash();
  }
  return (
    <div className='display_flex' style={{justifyContent:"center",height:"120px",position: "fixed",backgroundColor:"blue",width:"100%",zIndex: "100000",top: "0"}}>
        <div className='display_flex padding_component container'  style={{width: "100%"}}>
          <div style={{paddingTop:"30px"}}>
            <img onClick={redirectHome} className='img_logo_sm_controle' src={LogoSm} alt="Logo SmControle" />
          </div>
            <div className='display_flex gap_desktop' style={{marginLeft:"auto",marginRight:"40px",alignItems:"center"}}>
                <div onClick={onClickAnalise} className='fz_header_desktop'> Análise</div>
                <div onClick={onClickQuemSomos} className='fz_header_desktop'> Quem Somos </div>
                <div onClick={onClickServices} className='fz_header_desktop'> Serviços </div>
                <div onClick={onClickContato} className='fz_header_desktop'> Contato</div>
            </div>
        </div>
    </div>
  )
}

export default Header
