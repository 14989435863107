import React,{useEffect} from 'react'
import Header from "../Common/Desktop/Header";
import Footer from '../Common/Footer';


const PrivacyPolicyDesktop = ({scrollToHash}) => {
  useEffect(() => {
    scrollToHash();
    window.addEventListener('hashchange', scrollToHash);
    return () => {
      window.removeEventListener('hashchange', scrollToHash);
    };

  },);
  return (
    <>
    <Header/>
    <div className='elements_center' style={{backgroundColor:"white",marginTop:"40px",color:"black"}}>
    <div style={{width:"80%"}} >
        <h3> Data de Vigência: 31/12/2024</h3>
        <br />
        <h3>Agradecemos por escolher interagir conosco por meio dos formulários em nossos anúncios no Google Ads. Esta política de privacidade descreve como tratamos as informações pessoais coletadas por meio desses formulários. Ao enviar suas informações, você concorda com os termos desta política.</h3>
        <br />
        <h3>1. Informações Coletadas:</h3>
        <br />
        <h3> 1.1.Informações Pessoais</h3>
        <h3>- Nome</h3>
        <h3>- Endereço de e-mail</h3>
        <h3>- Endereço IP</h3>
        <h3>- Tipo de dispositivo</h3>
        <h3>- Navegador utilizado</h3>
        <h3>- Dados de cookies</h3>
        <br />
        <h3>2. Uso das Informações:</h3>
        <br />
        <h3>2.1. Processamento de Leads:</h3>
        <h3> - As informações coletadas serão utilizadas para processar sua solicitação, responder às suas perguntas ou fornecer os serviços solicitados.</h3>
        <br />
        <h3>2.2. Comunicações:</h3>
        <h3>- Podemos utilizar seu endereço de e-mail e número de telefone para enviar comunicações relacionadas aos nossos produtos, serviços ou informações relevantes.</h3>
        <br />
        <h3>2.3. Melhoria dos Serviços:</h3>
        <h3> - As informações coletadas nos ajudarão a melhorar nossos produtos e serviços, bem como a personalizar nossa comunicação para atender às suas necessidades.</h3>
        <br />
        <h3>3. Compartilhamento de Informações:</h3>
        <br />
        <h3>3.1. Parceiros de Serviço:</h3>
        <h3>- Podemos compartilhar suas informações com prestadores de serviços terceirizados que nos ajudam a fornecer e melhorar nossos serviços.</h3>
        <br />
        <h3>3.2. Requisitos Legais:</h3>
        <h3>- Reservamo-nos o direito de divulgar suas informações pessoais se exigido por lei ou no cumprimento de uma ordem judicial.</h3>
        <br />
        <h3>4. Segurança:</h3>
        <br />
        <h3>4.1. Medidas de Segurança:</h3>
        <h3>- Implementamos medidas de segurança para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição não autorizada.</h3>
        <br />
        <h3>5. Cookies:</h3>
        <br />
        <h3>5.1. Uso de Cookies:</h3>
        <h3>- Podemos usar cookies para coletar informações técnicas e melhorar sua experiência de usuário. Você pode configurar seu navegador para recusar cookies, mas isso pode afetar a funcionalidade do site.</h3>
        <br />
        <h3>6. Seus Direitos:</h3>
        <br />
        <h3>6.1. Acesso e Correção:</h3>
        <h3>- Você tem o direito de acessar suas informações pessoais e corrigi-las, se necessário.</h3>
        <br />
        <h3>6.2. Cancelamento de Inscrição:</h3>
        <h3>- Você pode cancelar a inscrição em nossas comunicações a qualquer momento, seguindo as instruções fornecidas nas mensagens ou entrando em contato conosco.</h3>
        <br />
        <h3>7. Alterações nesta Política:</h3>
        <br />
        <h3>7.1. Atualizações:</h3>
        <h3> - Esta política pode ser atualizada periodicamente. Notificaremos sobre quaisquer alterações significativas.</h3>
        <br />
        <h3>Ao continuar a interagir conosco por meio dos formulários em nossos anúncios no Google Ads, você concorda com os termos desta política de privacidade. Se tiver dúvidas ou preocupações, entre em contato conosco.</h3>
        <br />
        <h3>Email: smcontroleq@gmail.com</h3>
    </div>
    </div>
    <Footer/>
    </>
  )
}

export default PrivacyPolicyDesktop
