import React from 'react'
import { TEXTOS } from '../../constants/constants'
import { setPage } from '../../store/pageSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'


const DropDownMenu = ({onClickMenuBurguer}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    function scrollToHash() {
        if (window.location.hash) {
          const targetElement = document.querySelector(window.location.hash);
      
          if (targetElement) {
            const offset = targetElement.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: offset, behavior: 'smooth' });
          }
        }
      }
    const onClickAnalise = async() =>{
        dispatch(setPage("first"));
        navigate(TEXTOS.pathAnalise);
        scrollToHash();
        onClickMenuBurguer();
    }
    const onClickQuemSomos = async() =>{
        dispatch(setPage("first"));
        navigate(TEXTOS.pathQuemSomos);
        scrollToHash();
        onClickMenuBurguer();
    }
    const onClickServices = async() =>{
        dispatch(setPage("second"));
        navigate(TEXTOS.pathServices);
        onClickMenuBurguer();
    }
    const onClickContato = async() =>{
        dispatch(setPage("first"));
        navigate(TEXTOS.pathContato);
        scrollToHash();
        onClickMenuBurguer();
    }
    return (
        <>
        <div style={{display:"flex",justifyContent:"flex-end",marginTop:"120px"}}>
            <div className='dropDowmMenu' style={{right:"5px"}}>
                <div className='dropDowmMenuUnity'>
                    <h4 onClick={onClickAnalise} href={TEXTOS.pathAnalise}>
                        Análise
                    </h4>
                </div>
                <div className='dropDowmMenuUnity'>
                    <h4 onClick={onClickQuemSomos} href={TEXTOS.pathQuemSomos}>
                        Quem Somos
                    </h4>
                </div>
                <div className='dropDowmMenuUnity'>
                    <h4 onClick={onClickServices} href={TEXTOS.pathServices}>
                        Serviços
                    </h4>
                </div>
                <div className='dropDowmMenuUnity'>
                    <h4 onClick={onClickContato} href={TEXTOS.pathContato}>
                        Contato
                    </h4>
                </div>
            </div>
        </div>
        </>
    )
}

export default DropDownMenu
