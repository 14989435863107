import React,{useEffect} from 'react';
import LogoYolo from "../../assets/images/LogoSmFundoAzul.svg"
import { setPage } from '../../store/pageSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


const Footer = () => {  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function scrollToHash() {
    if (window.location.hash) {
      const targetElement = document.querySelector(window.location.hash);
  
      if (targetElement) {
        const offset = targetElement.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }
    }
  }

  const onClickQuemSomos = async() =>{
    dispatch(setPage("first"));
    navigate("/#smcontrole");
    scrollToHash();
  }
  const onClickAnalise = async() =>{
    dispatch(setPage("first"));
    navigate("/#analise");
    scrollToHash();
  }
  const onClickServices = async() =>{
    dispatch(setPage("second"));
    navigate("/");
  }
  return (
    <>

    <div style={{width:"100%",display:"flex",marginTop:"30px",backgroundColor:"blue"}}>
      <div className='elements_center' style={{width:"33%"}}>
        <img className='img_footer' src={LogoYolo} alt="Logo Yolo" />
      </div>
      <div className='elements_center' style={{width:"33%"}}>
          <div className="footer_contact_widget">
            <h4 style={{fontWeight:"700",fontSize:"18px"}}>Contato</h4>
            <ul className="list-unstyled">
              <li>
                  <a href='/#contato' style={{fontWeight:"500",fontSize:"16px"}}>Endereço</a>
              </li>
              <li>
                  <a href='/#contato'  style={{fontWeight:"500",fontSize:"16px"}}>Recife - PE</a>
              </li>
              <li>
                <a  href='/#contato' style={{fontWeight:"500",fontSize:"16px"}}>Fale conosco</a>
              </li>
            </ul>
          </div>
        </div>   
      <div className='elements_center' style={{width:"33%"}}>
        <div className="footer_qlink_widget">
          <h4 style={{fontWeight:"700",fontSize:"18px"}}>Links</h4>
          <ul className="list-unstyled">
            <li>
              <h4 onClick={onClickQuemSomos} style={{fontWeight:"500",fontSize:"16px",cursor:"pointer"}}>Quem somos</h4>
            </li>
            <li>
              <h4 onClick={onClickAnalise} style={{fontWeight:"500",fontSize:"16px",cursor:"pointer"}}>Análise</h4>
            </li>
            <li>
              <h4 onClick={onClickServices} style={{fontWeight:"500",fontSize:"16px",cursor:"pointer"}}>Serviços</h4>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </>
  )
}

export default Footer
