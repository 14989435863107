import React from 'react'
import InitialPageIndex from '../../components/InitialPage/InitialPageIndex'
import {useSelector } from 'react-redux/es/hooks/useSelector'
import ServicesIndex from '../../components/Services/ServicesIndex'

const InitialPage = () => {
  const page_store = useSelector(state=>state.pageForm.page);
  return (
    <>
    { page_store==="first" && <InitialPageIndex/>}
    {page_store==="second" && <ServicesIndex/>}
    </>
    
  )
}

export default InitialPage
