import React from 'react'
import PrivacyPolicyIndex from '../../components/PrivacyPolicy/PrivacyPolicyIndex'

const PrivacyPolicy = () => {
  return (
    <>
    <PrivacyPolicyIndex/>
    </>
  )
}

export default PrivacyPolicy

