import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page:'first',
};

const pageFormSlice = createSlice({
  name: 'pageForm',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
  },
});

export const { setPage } = pageFormSlice.actions;

export default pageFormSlice.reducer;