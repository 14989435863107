import React, { useState,useEffect } from 'react'
import LogoSmControle from "../../../assets/images/LogoSMOficial.svg";
import menuHamburguer from "../../../assets/images/menu_hamburguer_blue.png"
import DropDownMenu from '../DropDownMenu';
import { useNavigate } from 'react-router-dom';


const Header = () => {  
  const [showDropDowmMenu, setShowDropDowmMenu] = useState(false);
  const onClickMenuBurguer = () =>{
    setShowDropDowmMenu(!showDropDowmMenu);
  }
  const navigate = useNavigate();
  const redirectHome = () =>{
    navigate("/");
  }
  return (
    <>
    <div className='display_flex' style={{position:"fixed",top:"0",zIndex:"100000",backgroundColor:"#ffffff",width:"100%"}}>
      <div className='display_flex padding_component_mobile' style={{marginTop:"30px",backgroundColor:"#ffffff",width:"100%"}}>
          <img onClick={redirectHome} className='img_header_mobile' src={LogoSmControle} alt="Logo SmControle" />
          <img style={{marginLeft:"auto"}} className='img_header_mobile' src={menuHamburguer} alt="Menu Hamburguer" onClick={onClickMenuBurguer} />
      </div>
    </div>
    {showDropDowmMenu && <DropDownMenu onClickMenuBurguer={onClickMenuBurguer}/> }
    </>
  )
}

export default Header
