import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import FisicoQuimico from "../../assets/images/Serviços/fisicoquimica.svg";
import Cromatografia from "../../assets/images/Serviços/cromatografia.svg";
import AnaliseFluentes from "../../assets/images/Serviços/analisedefluentes.svg";
import AnaliseMaterialConstrucao from "../../assets/images/Serviços/analisedematerialdeconstrucao.svg";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPage } from '../../store/pageSlice';

const CarroselServices = () => {
  const images = [FisicoQuimico, Cromatografia, AnaliseFluentes, AnaliseMaterialConstrucao];
  const paths_images = ['/#fisicoquimica', '/#cromatografia', '/#analisedeefluentes', '/#analisematerialdeconstrucao'];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const navigateTo = async (destination) => {
    dispatch(setPage("second"));
    navigate(destination);
  };

  const selectImage = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='display_flex' style={{ justifyContent: "center",marginTop:"30px" }}>
      <Carousel
        showArrows={false}
        showThumbs={false}
        selectedItem={currentImageIndex}
        onChange={(index) => setCurrentImageIndex(index)}
        interval={5000}
      >
        {images.map((image, index) => (
          <div key={index} onClick={() => navigateTo(paths_images[index])}>
            <img
              src={image}
              alt={`Imagem ${index + 1}`}
              className='height_img_carrosel'
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarroselServices;

