import React,{useState} from 'react'
import MapContainer from '../MapContainer'
import IconLocation from "../../../assets/images/IconsContact/icon_localizacao.svg"
import IconCellphone from "../../../assets/images/IconsContact/telefone.svg"


const TalkToUs = ({nome,handleNomeChange,email,handleEmailChange,telefone,handleTelefoneChange,motivo,handleMotivoChange,message,handleMessageChange,onClickButtonSubmit}) => {
    return (
        <>
            <div style={{width:"100%"}} >
            <div className='display_flex' style={{justifyContent:"center"}}>
                <div style={{width:"100%"}}>
                <div className='display_flex col-md-12' style={{justifyContent:"center"}}>
                    <h2 className='fz_details_title_sm_control font-family-k2d' style={{color:"blue"}}> Fale conosco!</h2>
                </div>
                    <div className='display_flex col-md-12 elements_center' style={{marginTop:"30px"}}>
                        <div style={{width:"80%"}}>
                            <input
                                id="nome"
                                name="nome"
                                className="form-control"
                                required="required"
                                type="text"
                                placeholder="Seu nome"
                                value={nome}
                                onChange={handleNomeChange}
                            />
                        </div>
                    </div>
                    <div className='display_flex col-md-12 elements_center' style={{marginTop:"30px"}}>
                        <div style={{width:"80%"}}>
                            <input
                                id="email"
                                name="email"
                                className="form-control"
                                required="required"
                                type="email"
                                placeholder="Seu Email"
                                value={email}
                                onChange={handleEmailChange}
                            />
                        </div>
                    </div>

                    <div className='display_flex col-md-12 elements_center' style={{marginTop:"30px"}}>
                        <div style={{width:"80%"}}>
                            <input
                                id="telefone"
                                name="telefone"
                                className="form-control"
                                required="required"
                                type="text"
                                placeholder="Telefone"
                                value={telefone}
                                onChange={handleTelefoneChange}
                            />
                        </div>
                    </div>

                    <div className='display_flex col-md-12 elements_center' style={{marginTop:"30px"}}>
                        <div style={{width:"80%"}}>
                            <input
                                id="motivo"
                                name="motivo"
                                className="form-control"
                                required="required"
                                type="text"
                                placeholder="Motivo do contato"
                                value={motivo}
                                onChange={handleMotivoChange}
                            />
                        </div>
                    </div>

                    <div className="display_flex col-md-12 elements_center" style={{marginTop:"30px"}}>
                        <div style={{ width: "80%" }}>
                            <textarea
                                name="message"
                                id="message"
                                className="form-message"
                                rows="5"
                                placeholder="Mensagem"
                                value={message}
                                onChange={handleMessageChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className="display_flex col-md-12 elements_center" style={{marginTop:"50px"}}>
                    <div style={{width:"80%"}}>
                        <button className='btn_submit_values' onClick={onClickButtonSubmit} type="submit">Enviar</button>
                    </div>
                </div>
                </div>
            </div>
          </div>

          <div style={{width:"100%"}} >
            <div className='display_flex col-md-12' style={{justifyContent:"center"}}>
                    <h2 className='fz_details_title_sm_control font-family-k2d' style={{color:"blue"}}> Localização</h2>
            </div>

            <div >
                <MapContainer/>
                <div style={{marginLeft:"7%",paddingTop:"210px"}}>
                    <div style={{display:"flex",alignItems:"center",height: "100%",gap:"5%"}}>
                        <img className='img_20' src={IconCellphone} alt="Icon de Telefone" />
                        <h5 style={{fontWeight:"400",margin:"0",color:"#218ce6"}}> (81) 3428-3284 </h5>
                    </div>
                    <div style={{display:"flex",marginTop:"10px",alignItems:"center",height: "100%",gap:"5%"}}>
                        <img className='img_20' src={IconLocation} alt="Localização" />
                        <h5 style={{fontWeight:"400", margin: "0",color:"#218ce6"}}> R. Florentino Olímpio dos Santos, 54 - Afogados, Recife - PE, 50830-470</h5>
                    </div>
                </div>
            </div>

          </div>

        </>
    )
}

export default TalkToUs
