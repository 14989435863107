import React from 'react'
import Machine from  "../../../assets/images/maquina-smcontrole.svg"
import { TEXTOS } from '../../../constants/constants'


const DetailsSmControle = () => {
  return (
    <>
      <div style={{width:"100%",marginTop:"120px"}}>
        <img src={Machine} alt="Dona da Empresa" style={{width:"100%",maxWidth:"100%",height:"100%"}} />
      </div>
      <div className='margin_5'>
        <div style={{width:"100%"}} >
            <h2 className='fz_details_title_sm_control font-family-k2d'> O que é?</h2>
            <h4 className='fz_details_sm_control'>
              {TEXTOS.WhatIsSmControle}
             </h4>
        </div>
      </div>
    </>  
  )
}

export default DetailsSmControle
