import React,{useState,useEffect} from 'react'
import ServicesDesktop from './ServicesDesktop'
import ServicesMobile from './ServicesMobile'
import {setMotivo} from "../../store/dataFormSlice";
import { setPage } from '../../store/pageSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const ServicesIndex = () => {
    const [screenSize, setScreenSize] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        const handleResize = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 767) {
            setScreenSize('desktop');
        } else {
            setScreenSize('smartphone');
        }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    function scrollToHash() {
      if (window.location.hash) {
        const targetElement = document.querySelector(window.location.hash);
    
        if (targetElement) {
          const offset = targetElement.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({ top: offset, behavior: 'smooth' });
        }
      }
  }

  const onClickButtonService = async (typeService) =>{
    dispatch(setPage("first"));
    dispatch(setMotivo(typeService));
    navigate("/#contato");
  }

  return (
    <div>
      {screenSize === 'desktop' && <ServicesDesktop scrollToHash={scrollToHash} onClickButtonService={onClickButtonService}/>}
      {screenSize === 'smartphone' && <ServicesMobile scrollToHash={scrollToHash} onClickButtonService={onClickButtonService}/>}
    </div>
  );
}

export default ServicesIndex
