import React,{useEffect} from 'react'
import Header from '../Common/Mobile/Header'
import Footer from '../Common/Footer'
import FirstComponent from './FirstComponent'
import { TEXTOS } from '../../constants/constants';
import FisicoQuimico from "../../assets/images/Serviços/fisicoquimica.svg";
import Cromatografia from "../../assets/images/Serviços/cromatografia.svg";
import AnaliseFluentes from "../../assets/images/Serviços/analisedefluentes.svg";
import AnaliseMaterialConstrucao from "../../assets/images/Serviços/analisedematerialdeconstrucao.svg";

const ServicesMobile = ({scrollToHash,onClickButtonService}) => {
  useEffect(() => {
    scrollToHash();
    window.addEventListener('hashchange', scrollToHash);
    return () => {
      window.removeEventListener('hashchange', scrollToHash);
    };
  },);
  const listFisicoQuimica = [TEXTOS.textFisicoQuimica1,TEXTOS.textFisicoQuimica2,TEXTOS.textFisicoQuimica3];
  const listCromatografia = [TEXTOS.textCromatrografia1,TEXTOS.textCromatrografia2,TEXTOS.textCromatrografia3,TEXTOS.textCromatrografia4];
  const listAnaliseFluentes = [TEXTOS.textAnaliseFluentes1];
  const listAnaliseConstrucao = [TEXTOS.textAnaliseMaterialConstruçao1,TEXTOS.textAnaliseMaterialConstruçao2];
  return (
    <>
    <Header/>
    <div style={{marginTop:"100px"}}></div>
    <FirstComponent titleComponent={"Físico Química"} arrayTexts={listFisicoQuimica} actionButton={onClickButtonService} imageService={FisicoQuimico} idService={"fisicoquimica"}/>
    <FirstComponent titleComponent={"Cromatografia"} arrayTexts={listCromatografia} actionButton={onClickButtonService} imageService={Cromatografia} idService={"cromatografia"}/>
    <FirstComponent titleComponent={"Análise de efluentes"} arrayTexts={listAnaliseFluentes} actionButton={onClickButtonService} imageService={AnaliseFluentes} idService={"analisedeefluentes"}/>
    <FirstComponent titleComponent={"Análise material de construção"} arrayTexts={listAnaliseConstrucao} actionButton={onClickButtonService} imageService={AnaliseMaterialConstrucao} idService={"analisematerialdeconstrucao"}/>
    <Footer/>
    </>
  )
}

export default ServicesMobile
