import React from 'react'


const FirstComponent = ({titleComponent,arrayTexts,actionButton,imageService,idService}) => {
  return (
    <>
    <div className='display_flex' style={{justifyContent:"center"}}>
        <div className='display_flex padding_component container' id={idService} style={{width:"100%",marginTop:"30px"}}>
            <img style={{maxWidth:"100%",width:"100%"}} src={imageService} alt="Foto" />
        </div>
    </div>

    <div className='display_flex' style={{justifyContent:"center"}}>
        <div className='display_flex padding_component container' style={{justifyContent:"end",marginTop:"20px",width:"90%"}}>
            <button onClick={()=>actionButton(titleComponent)} className='btn_global'>
            Fale conosco
            </button>
        </div>
    </div>

    <div className='display_flex' style={{justifyContent:"center"}}>
        <div className='display_flex padding_component container' style={{flexDirection:"column",width:"90%"}}>
        {arrayTexts.map((text) =>(
            <div className='' style={{marginTop:"10px"}}>
                <h4 className='fz_details_sm_control'>{text}</h4>
            </div>
        ))}
         </div>
    </div>

    </>
  )
}

export default FirstComponent
