import React,{useEffect,useState} from 'react'
import Header from '../Common/Desktop/Header'
import DetailsSmControle from '../Common/DetailsSmControle'
import DetailsSmControleMobile from '../Common/Mobile/DetailsSmControle'
import WhoWeAre from '../Common/WhoWeAre'
import WhoWeAreMobile from '../Common/Mobile/WhoWeAre'
import TalkToUs from '../Common/TalkToUs'
import Footer from '../Common/Footer'
import FeaturedCustomers from '../Common/FeaturedCustomers'
import CarroselServices from '../Common/CarroselServices'


const InitialPageDesktop = ({nome,handleNomeChange,email,handleEmailChange,telefone,handleTelefoneChange,motivo,handleMotivoChange,message,handleMessageChange,onClickButtonSubmit,scrollToHash}) => {
  useEffect(() => {
    scrollToHash();
    window.addEventListener('hashchange', scrollToHash);
    return () => {
      window.removeEventListener('hashchange', scrollToHash);
    };

  },);
  const [screenSize, setScreenSize] = useState('');
    useEffect(() => {
        const handleResize = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 1000) {
            setScreenSize('bigger');
        } else {
            setScreenSize('smaller');
        }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);
  return (
    <>
    <Header/>
    <div id='analise' style={{paddingTop:"120px"}}></div>
    {screenSize==="bigger" && <DetailsSmControle/>}
    {screenSize==="smaller" && <DetailsSmControleMobile/>}
    <div id='smcontrole' style={{marginBottom:"60px"}}></div>
    {screenSize==="bigger" && <WhoWeAre/>}
    {screenSize==="smaller" && <WhoWeAreMobile/>}
    <CarroselServices desktop={true}/>
    <div id='contato' style={{marginBottom:"71px"}}></div>
    <TalkToUs nome={nome} handleNomeChange={handleNomeChange} email={email} handleEmailChange={handleEmailChange} telefone={telefone} handleTelefoneChange={handleTelefoneChange} motivo={motivo} handleMotivoChange={handleMotivoChange} message={message} handleMessageChange={handleMessageChange} onClickButtonSubmit={onClickButtonSubmit}/>
    <FeaturedCustomers/>
    <Footer />
    </>
  )
}

export default InitialPageDesktop
