import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Route as ReactRoute } from 'react-router'; // Importa Route do pacote react-router
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import InitialPage from './pages/InitialPage/InitialPage';
import Services from './pages/Services/Services';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import { Provider } from 'react-redux';
import store from './store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <ReactRoute path="/" element={<InitialPage />} />
            <ReactRoute path="/services" element={<Services />} />
            <ReactRoute path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
