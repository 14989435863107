import React from 'react'
import Machine from "../../assets/images/maquina-smcontrole.svg"
import MachineJpg from "../../assets/images/Maquina_SmControle.jpg"
import { TEXTOS } from '../../constants/constants'


const DetailsSmControle = () => {
  return (
    <>
    <div className='display_flex' style={{ justifyContent: "center" }}>
    <div className='display_flex padding_component container' style={{ marginTop: "20px" }}>
      <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
        <img src={MachineJpg} alt="Dona da Empresa" style={{ width: "100%", maxWidth: "100%",height:"100%" }} />
      </div>
      <div style={{ width: "50%", marginLeft: "30px", display: "flex", flexDirection: "column" }}>
        <h2 className='fz_details_title_sm_control font-family-k2d margin_top_0'> O que é?</h2>
        <h4 className='fz_details_sm_control texto-justificado'>
          {TEXTOS.WhatIsSmControle}
        </h4>
      </div>
    </div>
  </div>

    </>
  )
}

export default DetailsSmControle
