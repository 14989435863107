import React,{useState,useEffect} from 'react'
import PrivacyPolicyDesktop from './PrivacyPolicyDesktop'
import PrivacyPolicyMobile from './PrivacyPolicyMobile'


const PrivacyPolicyIndex = () => {
  const [screenSize, setScreenSize] = useState('');
    useEffect(() => {
        const handleResize = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 767) {
            setScreenSize('desktop');
        } else {
            setScreenSize('smartphone');
        }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    function scrollToHash() {
      if (window.location.hash) {
        const targetElement = document.querySelector(window.location.hash);
    
        if (targetElement) {
          const offset = targetElement.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({ top: offset, behavior: 'smooth' });
        }
      }
  }
  return (
    <>
     {screenSize === 'desktop' && <PrivacyPolicyDesktop scrollToHash={scrollToHash}/>}
      {screenSize === 'smartphone' && <PrivacyPolicyMobile scrollToHash={scrollToHash}/>}
    </>
  )
}

export default PrivacyPolicyIndex
