export const TEXTOS = {
    WhatIsSmControle: 'A SM Controle de Qualidade é uma empresa baseada em inovação e pioneirismo. Com Drª Suzana Martorelli, a empresa se mantém com a missão de ser referência em análise de óleos, materiais de construção e efluentes, dentro do Nordeste. Na SM Controle de Qualidade, dedicamos nossos esforços à excelência em garantia de qualidade e serviços de controle de qualidade. Somos uma empresa que se orgulha de fornecer soluções especializadas para atender às necessidades únicas de nossos clientes em uma ampla variedade de setores. Se você procura confiabilidade, precisão e expertise em controle de qualidade, você está no lugar certo, nosso laboratório é líder na análise de óleo isolante, oferecendo serviços de alta qualidade para ajudar a garantir a segurança e o desempenho dos seus sistemas elétricos. Sendo a primeira empresa privada a realizar análise de óleos no Nordeste, ainda na década de 90. Desde então, a empresa oferece os serviços de análise de óleos isolantes, lubrificantes, sendo eles vegetais, ou minerais. Além disso, oferecemos outros serviços, como análise de materiais de construção e efluentes.',
    WhoWeAre1: 'SUZANA MARTORELLI - Fundadora e química técnica da empresa, estampa em seus anos de profissão sendo referência com as realizações dos prêmios Suzana Martorelli para os maiores contribuidores ao segmento Elétrico do Brasil, segundo a Vegoor. A Drª Suzana Martorelli possui mais de 50 anos de mercado nacional e internacional, com ética profissional incontestável. Além da atividade comercial, a Drª Suzana Martorelli também foi agente primordial dentro do Conselho Regional de Química, ABNT e COBEI.',
    WhoWeAre2: 'EDMILSON OLIVEIRA DOS SANTOS – Químico Industrial e Mestre em Engenharia Química. Em atividade supervisionando e efetuando análises cromatográficas de óleos isolantes, implantando novas técnicas analíticas e supervisionando a calibração/aferição de instrumentos.',
    WhoWeAre3: 'MARIELDA MARIA DA SILVA – Química Industrial com pós-graduação em Meio ambiente. Em atividade supervisionando e efetuando análises de água e efluentes, materiais de construção, ligas metálicas, solos e produtos diversos.',
    WhoWeAre4: 'ADEMILTON PERES TEIXEIRA – Técnico químico. Em atividade efetuando análises cromatográficas, análises de celulose coleta de óleos minerais isolantes, análises de água e efluentes, materiais de construção, ligas metálicas, solos e produtos diversos.',
    WhoWeAre5: 'FELIPE MERADAK DE SOUZA – Técnico químico. Em atividade efetuando análises de água e efluentes, materiais de construção, ligas metálicas, solos e produtos diversos.',
    pathServices:"/",
    pathAnalise:"/#analise",
    pathContato:"/#contato",
    pathQuemSomos:"/#smcontrole",
    textFisicoQuimica1:"Você sabia que, além de manter as máquinas industriais bem lubrificadas, também é importante fazer a análise da atuação do óleo lubrificante? Dessa forma, podemos evitar paradas inesperadas dos equipamentos e problemas que podem custar tempo e dinheiro. Por meio de relatórios gerados, a indústria consegue saber as condições ideais do fluido e dos ativos. Dessa forma, a equipe de manutenção pode se programar para identificar antecipadamente, possíveis falhas que comprometem o desempenho e a qualidade da produção, gerando prejuízos irreversíveis.",
    textFisicoQuimica2:"A análise físico-química de óleo isolante abrange diversos ensaios nos quais cada um fornece um resultado essencial para a obtenção de um diagnóstico completo das condições físico-químicas do fluido isolante, tanto novo quanto em uso. Através da análise físico-química de óleo isolante pode ser feita a avaliação das condições do fluido isolante empregado em equipamentos elétricos isolados a óleo. Em equipamentos elétricos isolados a óleo, o fluido dielétrico tem a função de atuar como um isolante elétrico e como fluido de troca de calor, o que assegura o funcionamento adequado do equipamento. ",
    textFisicoQuimica3:"As propriedades físico-químicas dos óleos minerais, vegetais e fluidos silicones, por exemplo, garantem que estes fluidos possam ser utilizados com essa finalidade e que os equipamentos elétricos operem de forma segura. Dentro da análise físico-química de óleo isolante podem ser destacados os ensaios de teor de água, densidade, cor, rigidez dielétrica, tensão interfacial, fator de potência e índice de neutralização.",
    textCromatrografia1:"Os gases produzidos durante o funcionamento de equipamentos elétricos isolados a óleo podem ser identificados e quantificados através da técnica de cromatografia gasosa realizada pelo laboratório de análise cromatografia gasosa. A aquisição desta informação é essencial para a identificação da presença de falhas elétricas nos equipamentos elétricos e execução de manutenções corretivas.",
    textCromatrografia2:"Um laboratório de análise cromatografia gasosa conta com a indispensável assistência do cromatógrafo. Este equipamento é composto principalmente pelo injetor, a coluna, o forno e o detector. Através do cromatógrafo, os gases extraídos das amostras de óleo são separados, identificados e quantificados. O laboratório de análise cromatografia gasosa é responsável, inicialmente, por realizar a extração dos gases dissolvidos em amostras de óleo isolante.",
    textCromatrografia3:"A cromatografia é uma técnica analítica amplamente utilizada em química e bioquímica para separar e identificar componentes de uma mistura com base em suas propriedades de interação com uma fase estacionária e uma fase móvel. Essa técnica é essencial para a análise de uma variedade de amostras, desde compostos químicos em laboratórios até substâncias orgânicas e inorgânicas em diversas aplicações, incluindo farmacologia, biologia, alimentos, petroquímica e muito mais.",
    textCromatrografia4:"A cromatografia funciona com base no princípio da partição, adsorção, exclusão ou afinidade, dependendo do tipo específico de cromatografia utilizada. Os componentes da amostra se movem através de uma coluna ou placa coberta por uma fase estacionária, enquanto uma fase móvel é usada para transportar a amostra através do sistema. A interação diferencial entre os componentes da amostra e a fase estacionária resulta em diferentes taxas de eluição, permitindo a separação dos componentes.",
    textAnaliseFluentes1:"Um dos objetivos da análise de efluentes é a possibilidade de reduzir a concentração de agentes poluidores que são lançados no meio ambiente. Por isso,urge a necessidade da constante monitoração dos processos produtivos industriais, que são os grandes responsáveis por lançar resíduos poluidores. Por isso,  o laboratório SM Controle de Qualidade – empresa de pesquisas que oferece serviços de análise de efluentes – realiza essa avaliação, além de análises físico-químicas e microbiológicas de águas, alimentos e efluentes, adotando sempre normas e legislações vigentes.",
    textAnaliseMaterialConstruçao1:"A construção civil é um segmento que engloba toda atividade relacionada ao planejamento, projeto, execução, manutenção e restauração de obras. Para garantir a qualidade e a segurança dos materiais utilizados durante esses processos, é fundamental procurar um laboratório de materiais de construção.",
    textAnaliseMaterialConstruçao2:"O laboratório de materiais de construção é um local especializado na realização de avaliações, testes e ensaios em materiais que são utilizados na construção civil. Entre os procedimentos realizados, é possível destacar como exemplos os ensaios mecânicos, ensaios químicos, ensaios magnéticos, além dos ensaios térmicos, em Cal, Areia, Solo e etc.",
};