import React from 'react'
import Manaira from "../../assets/images/manairaShopping.svg";
import Equatorial from "../../assets/images/ClientesDestaque/equatorial.svg";
import Neoenergia from "../../assets/images/ClientesDestaque/neoenergia.svg";
import Textil from "../../assets/images/ClientesDestaque/TbmTextil.svg";
import Tambau from "../../assets/images/ClientesDestaque/tambau.svg";


const FeaturedCustomers = () => {
  return (
    <>
        <div className='display_flex' style={{justifyContent:"center"}} >
            <div className='display_flex padding_component container' style={{marginTop:"10px"}}>
                <div className='display_flex col-md-12' style={{justifyContent:"center"}}>
                    <h2 className='fz_details_title_sm_control font-family-k2d' style={{color:"blue"}}> Clientes em Destaque</h2>
                </div>
            </div>
        </div>

        <div className='display_flex' style={{justifyContent:"center"}} >
            <div className='display_flex padding_component container' style={{marginTop:"20px"}}>
                <div style={{width:"20%"}}>
                    <img style={{width:"80%"}} src={Manaira} alt="Logo da SM" />
                </div>
                <div style={{width:"20%"}}>
                    <img style={{width:"80%"}} src={Equatorial} alt="Logo da SM" />
                </div>
                <div style={{width:"20%"}}>
                    <img style={{width:"80%"}} src={Neoenergia} alt="Logo da SM" />
                </div>
                <div style={{width:"20%"}}>
                    <img style={{width:"80%"}} src={Textil} alt="Logo da SM" />
                </div>
                <div style={{width:"20%"}}>
                    <img style={{width:"80%"}} src={Tambau} alt="Logo da SM" />
                </div>
            </div>
        </div>
    </>

  )
}

export default FeaturedCustomers
