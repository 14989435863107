import React from 'react'
import smCompany from "../../../assets/images/sm3.svg"
import { TEXTOS } from '../../../constants/constants'


const WhoWeAre = () => {
  return (
    <>
    <div style={{width:"100%"}}>
    <img src={smCompany} alt="Dona da Empresa" style={{width:"100%",maxWidth:"100%",height:"100%"}} />
    </div>

    <div className='margin_5'>
        <div style={{width:"100%"}} >
            <h2 className='fz_details_title_sm_control font-family-k2d'> Quem somos</h2>
            <h4 className='fz_details_sm_control'>
              {TEXTOS.WhoWeAre1}
            </h4>
            <h4 className='fz_details_sm_control'>
              {TEXTOS.WhoWeAre2}
            </h4>
            <h4 className='fz_details_sm_control'>
              {TEXTOS.WhoWeAre3}
            </h4>
            <h4 className='fz_details_sm_control'>
                {TEXTOS.WhoWeAre4}
            </h4>
        </div>
    </div>

    </>
  )
}

export default WhoWeAre
