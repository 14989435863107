import React,{useState,useEffect} from 'react'
import InitialPageDesktop from './InitialPageDesktop';
import InitialPageMobile from './InitialPageMobile';
import { useSelector } from 'react-redux';
import axios from 'axios';
// import sendEmail from "../../utils/sendEmail"


const InitialPageIndex = () => {
    const [screenSize, setScreenSize] = useState('');
    useEffect(() => {
        const handleResize = () => {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 767) {
            setScreenSize('desktop');
        } else {
            setScreenSize('smartphone');
        }
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    function scrollToHash() {
        if (window.location.hash) {
          const targetElement = document.querySelector(window.location.hash);
      
          if (targetElement) {
            const offset = targetElement.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: offset, behavior: 'smooth' });
          }
        }
    }
    const motivo_store = useSelector(state=>state.dataForm.motivo);
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [motivo, setMotivo] = useState(motivo_store);
    const [message, setMessage] = useState('');

    const handleNomeChange = (e) => {
        setNome(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleTelefoneChange = (e) => {
        setTelefone(e.target.value);
    };

    const handleMotivoChange = (e) => {
        setMotivo(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const onClickButtonSubmit = async () =>{
        const objForm = {
            nome: nome,
            email: email,
            motivo: motivo,
            telefone: telefone,
            mensagem: message
        };  
          console.log(objForm);
        try{
            const response = await axios.post('https://vqvp6ksuc8.execute-api.us-east-1.amazonaws.com/dev', objForm);
            setNome('');
            setEmail('');
            setTelefone('');
            setMotivo('');
            setMessage('');
        } catch(error){
            console.log(error);
        }
    }

  return (
    <div>
      {screenSize === 'desktop' && <InitialPageDesktop nome={nome} handleNomeChange={handleNomeChange} email={email} handleEmailChange={handleEmailChange} telefone={telefone} handleTelefoneChange={handleTelefoneChange} motivo={motivo} handleMotivoChange={handleMotivoChange} message={message} handleMessageChange={handleMessageChange} onClickButtonSubmit={onClickButtonSubmit} scrollToHash={scrollToHash}/>}
      {screenSize === 'smartphone' && <InitialPageMobile nome={nome} handleNomeChange={handleNomeChange} email={email} handleEmailChange={handleEmailChange} telefone={telefone} handleTelefoneChange={handleTelefoneChange} motivo={motivo} handleMotivoChange={handleMotivoChange} message={message} handleMessageChange={handleMessageChange} onClickButtonSubmit={onClickButtonSubmit} scrollToHash={scrollToHash}/>}
    </div>
  );
}

export default InitialPageIndex
