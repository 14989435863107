import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  motivo:'',
};

const dataFormSlice = createSlice({
  name: 'dataForm',
  initialState,
  reducers: {
    setMotivo: (state, action) => {
      state.motivo = action.payload;
    },
    clearMotivo: (state) => {
      state.motivo = '';
    },
  },
});

export const { setMotivo,clearMotivo } = dataFormSlice.actions;

export default dataFormSlice.reducer;