import React from 'react';
import ServicesIndex from '../../components/Services/ServicesIndex';

const Services = () => {
  return (
    <>
    <ServicesIndex/>
    </>
  )
}

export default Services
