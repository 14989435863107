import React from 'react'
import smCompany from "../../assets/images/Sm3Oficial.svg"
import smCompanyJpg from "../../assets/images/SM3.JPG"
import { TEXTOS } from '../../constants/constants'


const WhoWeAre = () => {
    return (
      <>
        <div className='display_flex' style={{justifyContent:"center"}}>
          <div className='display_flex padding_component container' style={{marginTop:"10px"}}>
            <div style={{width:"50%",marginRight:"30px"}} >
              <h2 className='fz_details_title_sm_control font-family-k2d margin_top_0'> Quem somos</h2>
              <h4 className='fz_details_sm_control texto-justificado'>
                {TEXTOS.WhoWeAre1}
              </h4>
              <h4 className='fz_details_sm_control texto-justificado'>
                {TEXTOS.WhoWeAre2}
              </h4>
              <h4 className='fz_details_sm_control texto-justificado'>
                {TEXTOS.WhoWeAre3}
              </h4>
              <h4 className='fz_details_sm_control texto-justificado'>
                  {TEXTOS.WhoWeAre4}
              </h4>
            </div>
            <div style={{width:"50%"}}>
              <img src={smCompanyJpg} alt="Dona da Empresa" style={{width:"100%",maxWidth:"100%",height:"100%"}} />
            </div>
          </div>
        </div>
      </>
    )
}

export default WhoWeAre
