import React, { Component } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';


const mapStyles = {
    width: '80%', // Defina a largura desejada
    height: '200px', // Defina a altura desejada
    position: "initial",
    marginLeft:"10%",
    marginRight:"10%",
};


class MapContainer extends Component {
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={14} // Defina o nível de zoom desejado
        style={mapStyles}
        initialCenter={{ lat: -8.0709355, lng: -34.9123244 }} // Defina as coordenadas iniciais
      />
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD1KXkHTWzhHru-8Gj0IXO0fSiHTkSuj0A' // Substitua pela sua chave de API
})(MapContainer);
